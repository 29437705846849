import { Component, OnInit } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';

import { Question, Category } from './model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  categories: Category[];

  private round: string;
  private categoriesPerRound = new Map<string, Category[]>();

  private showIds: string[];

  constructor(private firestore: AngularFirestore) {}

  ngOnInit() {
    const mock = false;
    if (mock) {
      this.round = 'single';
      this.categories = [];
      let ddCat = Math.floor(Math.random() * 6);
      let ddIndex = Math.floor(Math.random() * 3) + 2;
      let dd = ddCat * 5 + ddIndex;
      for (let i = 1; i <= 6; ++i) {
        let category = new Category('Title' + i, this.round);
        for (let j = 1; j <= 5; ++j) {
          let x = (i - 1) * 5 + j - 1;
          let value = (x == dd) ? 'pew-pew-pew' : ('$' + j * 200);
          let text = '(<a href="http://www.j-archive.com/media/2010-04-26_J_22.jpg" target="_blank">James Lipton</a>) What about html';
          let question = new Question('Is ' + j + ' your favorite number?' + text, 'No', false, value);
          if (x == dd) {
            console.log("Daily double:", category.title, question);
          }
          category.addQuestion(question);
        }
        this.categories.push(category);
      }
      return;
    }


    // Pick a random show.
    var subscription = this.firestore.collection('show_ids').snapshotChanges().subscribe(actions => {
      subscription.unsubscribe();
      this.showIds = [];
      actions.forEach(action => {
        this.showIds.push(action.payload.doc.id);
      });
      this.pickRandomShow();
    });
  }

  private pickRandomShow() {
    let index = Math.floor(Math.random() * this.showIds.length);
    console.log(index, this.showIds.length, this.showIds[index]);
    var subscription = this.firestore.collection('questions',
      ref => ref.where('show', '==', +this.showIds[index])).valueChanges().subscribe(questions => {
        subscription.unsubscribe();
        this.categoriesPerRound.clear();
        console.log(questions);
        questions.forEach(q => {
          let categories = this.categoriesPerRound.get(q['round']);
          if (!categories) {
            categories = [];
            this.categoriesPerRound.set(q['round'], categories);
          }

          let question = new Question(q['question'], q['answer'], q['old'], q['value']);
          let catName = q['category'];

          let category = null;
          for (let c of categories) {
            if (c.title == catName) {
              category = c;
              break;
            }
          }
          if (!category) {
            category = new Category(catName, q['round']);
            categories.push(category);
          }
          category.addQuestion(question);
        });

        this.updateRound('single');
      });
  }

  private updateRound(round) {
    this.round = round;
    this.categories = this.categoriesPerRound.get(round);
  }
}
