
export class Question {
  constructor(readonly question: string, readonly answer: string, readonly old: boolean, public value) {

  }
}

export class Category {
  questions: Question[] = [];

  constructor(readonly title: string, readonly round: string) {

  }

  isDoubleJeopardy(): boolean {
    return this.round === 'double';
  }

  addQuestion(question: Question) {
    this.questions.push(question);
  }
}