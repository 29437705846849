import { Component, Input, OnInit } from '@angular/core';

import { Category, Question } from '../model';

@Component({
  selector: 'category-column',
  templateUrl: './category-column.component.html',
  styleUrls: ['./category-column.component.css']
})
export class CategoryColumnComponent implements OnInit {

  @Input() category: Category;

  amounts: number[] = [];
  question: Question;
  answerShown: boolean = false;
  private ddQuestion: Question;
  private ddAmount: number;

  private amountToQuestion = new Map<string, Question>();

  constructor() { }

  ngOnInit() {
    let inc = this.category.questions[0].old ? 100 : 200;
    if (this.category.round === 'double')  {
      inc *= 2;
    }

    let missingAmounts = [];
    for (let i = 1; i <= 5; i++) {
      let amount = i * inc;
      missingAmounts.push(i * inc);
      this.amounts.push(amount);
    }

    this.ddQuestion = null;
    for (let q of this.category.questions) {
      let amount = +(q.value.substring(1));
      let missingIndex = missingAmounts.indexOf(amount);
      if (missingIndex != -1) {
        this.amountToQuestion.set(q.value, q);
        delete missingAmounts[missingIndex];
      } else {
        this.ddQuestion = q;
      }
    }

    this.ddAmount = 0;
    for (let amount of missingAmounts) {
      if (amount && this.ddAmount == 0 && this.ddQuestion) {
        this.ddAmount = amount;
        break;
      }
    }
  }

  formatAmount(amount: number) {
    if (amount == 0) {
      return '&nbsp';
    }
    return '$' + amount;
  }

  showQuestion(amount: number) {
    if (amount == 0) {
      return;
    }

    let key = '$' + amount;
    this.answerShown = false;
    let amountIndex = this.amounts.indexOf(amount);
    if (this.amountToQuestion.has(key)) {
      this.question = this.amountToQuestion.get(key);
    } else if (amount == this.ddAmount) {
      this.question = this.ddQuestion;
    } else {
      this.question = new Question('Question was left on the board', 'wuah wuah', false, '');
    }
    this.amounts[amountIndex] = 0;
  }

  showAnswer() {
    this.answerShown = true;
  }

  closeQuestion() {
    this.question = null;
  }
}
