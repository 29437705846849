// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB2OM4FCwbODaaRBBXvZ5PnyTJBYUsUpyM",
    authDomain: "all-the-questions.firebaseapp.com",
    databaseURL: "https://all-the-questions.firebaseio.com",
    projectId: "all-the-questions",
    storageBucket: "all-the-questions.appspot.com",
    messagingSenderId: "139218566894"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
